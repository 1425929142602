import { GalleryComponent2 } from "../Gallery/GalleryComponent2"
import { january2025Photos } from "../Gallery/LabPhotos/january2025/january2025Photos"
import "./LabText.scss"

export const January2025 = () => {
	return (
		<div className='january2025'>
			<h2 className='LabTitle'>rok szkolny 2024/25</h2>
			<h2 className='LabTitle'>Grudzień - Styczeń</h2>
			<p className='LabText2'>
				Odbyło się wiele ciekawych lekcji dzięki sprzętowi który pozyskaliśmy w
				ramach Laboratorium Przyszłości. Dzięki posiadaniu drukarki 3D uczniowie
				projektowali i drukowali ozdoby choinkowe, korzystaliśmy także z
				długopisów 3D przy pomocy których uczniowie wykonywali dekoracje
				świąteczne.
			</p>
			<p className='LabText2'>
				Uczniowie klasy piątej budowali fantastyczny pojazd i wprawiali go w
				ruch przy pomocy zestawu Modi. Klocki Modi zestaw eksperta
				wykorzystaliśmy także na zajęciach rozwijających kreatywność, dzieci
				wykonywały konstrukcje zgodnie z instrukcją, a były to latarka, alarm
				przeciwwłamaniowy.
			</p>
			<p className='LabText2'>
				Klocki Lego wykorzystywano na zajęciach języka polskiego oraz
				rewalidacji w klasach młodszych. Z dużym zaangażowaniem uczniowie
				dokumentują wszelkie imprezy i szkolne i klasowe.
			</p>
			<GalleryComponent2
				carouselId='january2025Photos'
				images={january2025Photos}
			/>
		</div>
	)
}
