import photo1 from "../../../../assets/labPhotos/january2025/photo1.jpg"
import photo2 from "../../../../assets/labPhotos/january2025/photo2.jpg"
import photo3 from "../../../../assets/labPhotos/january2025/photo3.jpg"
import photo4 from "../../../../assets/labPhotos/january2025/photo4.jpg"
import photo5 from "../../../../assets/labPhotos/january2025/photo5.jpg"
import photo6 from "../../../../assets/labPhotos/january2025/photo6.jpg"
import photo7 from "../../../../assets/labPhotos/january2025/photo7.jpg"
import photo8 from "../../../../assets/labPhotos/january2025/photo8.jpg"
import photo9 from "../../../../assets/labPhotos/january2025/photo9.jpg"
import photo10 from "../../../../assets/labPhotos/january2025/photo10.jpg"
import photo11 from "../../../../assets/labPhotos/january2025/photo11.jpg"
import photo12 from "../../../../assets/labPhotos/january2025/photo12.jpg"
import photo13 from "../../../../assets/labPhotos/january2025/photo13.jpg"
import photo14 from "../../../../assets/labPhotos/january2025/photo14.jpg"
import photo15 from "../../../../assets/labPhotos/january2025/photo15.jpg"
import photo16 from "../../../../assets/labPhotos/january2025/photo16.jpg"
import photo17 from "../../../../assets/labPhotos/january2025/photo17.jpg"
import photo18 from "../../../../assets/labPhotos/january2025/photo18.jpg"
import photo19 from "../../../../assets/labPhotos/january2025/photo19.jpg"
import photo20 from "../../../../assets/labPhotos/january2025/photo20.jpg"
import photo21 from "../../../../assets/labPhotos/january2025/photo21.jpg"
import photo22 from "../../../../assets/labPhotos/january2025/photo22.jpg"
import photo23 from "../../../../assets/labPhotos/january2025/photo23.jpg"
import photo24 from "../../../../assets/labPhotos/january2025/photo24.jpg"
import photo25 from "../../../../assets/labPhotos/january2025/photo25.jpg"
import photo26 from "../../../../assets/labPhotos/january2025/photo26.jpg"
import photo27 from "../../../../assets/labPhotos/january2025/photo27.jpg"
import photo28 from "../../../../assets/labPhotos/january2025/photo28.jpg"

export const january2025Photos = [
	{ srcSmall: photo1, alt: "Photo 1" },
	{ srcSmall: photo2, alt: "Photo 2" },
	{ srcSmall: photo3, alt: "Photo 3" },
	{ srcSmall: photo4, alt: "Photo 4" },
	{ srcSmall: photo5, alt: "Photo 5" },
	{ srcSmall: photo6, alt: "Photo 6" },
	{ srcSmall: photo7, alt: "Photo 7" },
	{ srcSmall: photo8, alt: "Photo 8" },
	{ srcSmall: photo9, alt: "Photo 9" },
	{ srcSmall: photo10, alt: "Photo 10" },
	{ srcSmall: photo11, alt: "Photo 11" },
	{ srcSmall: photo12, alt: "Photo 12" },
	{ srcSmall: photo13, alt: "Photo 13" },
	{ srcSmall: photo14, alt: "Photo 14" },
	{ srcSmall: photo15, alt: "Photo 15" },
	{ srcSmall: photo16, alt: "Photo 16" },
	{ srcSmall: photo17, alt: "Photo 17" },
	{ srcSmall: photo18, alt: "Photo 18" },
	{ srcSmall: photo19, alt: "Photo 19" },
	{ srcSmall: photo20, alt: "Photo 20" },
	{ srcSmall: photo21, alt: "Photo 21" },
	{ srcSmall: photo22, alt: "Photo 22" },
	{ srcSmall: photo23, alt: "Photo 23" },
	{ srcSmall: photo24, alt: "Photo 24" },
	{ srcSmall: photo25, alt: "Photo 25" },
	{ srcSmall: photo26, alt: "Photo 26" },
	{ srcSmall: photo27, alt: "Photo 27" },
	{ srcSmall: photo28, alt: "Photo 28" },
]
